/***
Customized Bootstrap Close Icon 
***/

.close {
	display: inline-block;
	margin-top: 0px;
	margin-right: 0px;
	width: 9px;
	height: 9px;
	background-repeat: no-repeat !important;
	text-indent: -10000px;
	outline: none;
	background-image: url("../images/remove-icon-small.png") !important;
}
  